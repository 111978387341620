// Deps
import 'lazysizes';
import '~/js/modules/share-social';
import '~/js/modules/footer';
import 'jquery.scrollbar';
import header from '~/js/modules/header';
import modal from '~/js/modules/magnific-popup';
import imgLoad from '~/js/modules/progressiveImgLoad';

const internals = {};
const browser = {
  firefox: /firefox/i.test(navigator.userAgent),
  macosx: /mac/i.test(navigator.platform),
  webkit: /webkit/i.test(navigator.userAgent) && !/edge\/\d+/i.test(navigator.userAgent)
};

/** General event binding */
internals.bindEvents = () => {
  $(document).on('click', '.js-magnific-popup', () => {

    const $contentToScroll = $('.js-modal-scroll');

    if (browser.macosx && !browser.webkit) {
      $contentToScroll.addClass('ios-firefox');
    }

    $contentToScroll.scrollbar({
      scrollStep: 20
    });
  });
};

const global = () => {
  const { bindEvents } = internals;

  header();
  imgLoad();
  modal();
  bindEvents();
};

export default global;
