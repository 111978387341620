import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import GoFetch from "~/js/modules/fetch-wrapper";
import CustomRecaptchaLogin from '~/js/components/form-controls/CustomRecaptchaLogin';
import ProfileOptions from "./ProfileOptions";


const fetch = new GoFetch();
const logInBtn = document.querySelector('.js-login-btn');
const logInBtn2 = document.querySelector('.js-login-btn-2');
const profileOptions = document.querySelector('.profile-options');
const profileOptions2 = document.querySelector('.profile-options-2');
const mainMenu = document.querySelector(".hm-menu-btn");
const headerBottom = document.querySelector(".header-bottom");


class AccountLogin extends Component {

  constructor(){
    super();
  const { env } = window.beaches_app;
    this.state = {
    username: "",
    password: "",
    staySignedIn: "",
    passwordShown: false,
    recaptcha: "",
    needRecaptcha: "",
    errorMsg: "",
    islogged: true,
    usertext: "",
    env
   };
  }


  static propTypes = {
    username: PropTypes.string,
    password: PropTypes.string,
    staySignedIn: PropTypes.bool,
    passwordShown: PropTypes.bool,
    recaptcha: PropTypes.string,
    needRecaptcha: PropTypes.bool,
    islogged: PropTypes.bool,
    usertext: PropTypes.string
  };

  static defaultProps = {
    staySignedIn: false,
    needRecaptcha: false,
    errorMsg: "", 
    usertext: "",
    islogged: true
  };



  componentDidMount() {
    this.getProfiles();
  }

  handleChange = (e) => {
    const { name, value, checked } = e.target;
    this.setState({ [name]: name === "staySignedIn" ? checked : value});
   
  };

  handleRecaptchaTokenChange = (token) => {
    this.setState({ recaptcha: token });
  };

  signInUser = () => {

    const urlLoginPrd = "https://accountscms.beaches.co.uk/api/auth/sign-in/";
    const urlLoginDev = "https://tstaccountscms.beaches.co.uk/api/auth/sign-in/";
    
    const urlLogin = this.state.env === "prd" ? urlLoginPrd : urlLoginDev;
    const userInput = document.querySelector('.login-email');
    const passwordInput = document.querySelector('.login-password');

    const { username, password, staySignedIn, recaptcha } = this.state;

    const params = { username, password, staySignedIn, recaptcha};
      
    userInput.classList.remove("user-outline");
    passwordInput.classList.remove("pas-outline");
     
      if (username === "" || password === "") {
        this.setState({ errorMsg: "Fields are required." });
        userInput.classList.add("user-outline");
        passwordInput.classList.add("pas-outline");
      } else {

        fetch.post(urlLogin, null, params).then((response) => {
          const { status = {}, error = {}, content = {} } = response;
       
          switch (status) {
            case 200:
              this.setState({ islogged: true });
              mainMenu.classList.remove("hm-menu-btn--active");
              headerBottom.classList.remove("header-bottom--open");
              location.reload();
              break;
            case 400:
              this.setState({ errorMsg: "The requested resource is not available at this time. Please try again later." });
              break;
            case 403:
              this.setState({ errorMsg: "Account needs to be enabled." });
              break;
            case 503:
              this.setState({ errorMsg: "We are currently experiencing problems loading your account, please try again later." });
              break;
            case 498:
              this.setState({ errorMsg: "Your session has been terminated. Please log in again." });
              break;
            case 500:
              this.setState({ errorMsg: "The requested resource is not available at this time. Please try again later." });
              break;
            case "error":
                this.setState({ errorMsg: "Internal Server Error." });
                break;
            default:
              this.setState({ islogged: false });

              if(content.passwordPendingChange == true){
                const urlResetPrd = "/my-account/forgot-password?isInvalid";
                const urlResetDev = "/my-account/forgot-password?isInvalid";
                const urlReset = this.state.env === "prd" ? urlResetPrd : urlResetDev;
                this.setState({ errorMsg: "Internal service error - password with outdated policy." });
                window.location.href = urlReset;
              }else if(content.maxAttemps === true && content.errors[0].code == "validation/recaptcha") {
                this.setState({ needRecaptcha: true, errorMsg: "This field is required." });
              } else {
                this.setState({ needRecaptcha: false, errorMsg: "Invalid credentials. The email and/or password you entered is incorrect. Please try again." });
                userInput.classList.add("user-outline");
                passwordInput.classList.add("pas-outline");
              }
              break;
          }
        }).catch(error => {
          this.setState({ errorMsg: "The requested resource is not available at this time. Please try again later." });
          console.log(error);
        }); 
      }
    };

  getProfiles = () =>{

    const urlProfilesPrd = 'https://accountscms.beaches.co.uk/api/loyalty/profiles/';
    const urlProfilesDev = 'https://tstaccountscms.beaches.co.uk/api/loyalty/profiles/';
  
    const urlProfiles = this.state.env === 'prd' ? urlProfilesPrd : urlProfilesDev;

    fetch.get(urlProfiles)
    .then(response => {
      const { content, status = {} } = response;

     
      if(status == 200){

        logInBtn.classList.add("pd-btn");
        logInBtn2.classList.add("pd-btn");
        this.setState( {usertext: "Hi, " + content.primary_member.first_name[0].toUpperCase() + content.primary_member.first_name.toLowerCase().slice(1)});
      
      
        const profileName = content.primary_member.first_name;
        logInBtn.classList.add('js-rewards-btn');
        logInBtn2.classList.add('js-rewards-btn');
        logInBtn.innerHTML= "Hi, " + profileName[0].toUpperCase() + profileName.toLowerCase().slice(1) +  `  <i id="acc-arrow" class="icon-arrow-right ic-arrow"></i>`;
        logInBtn2.innerHTML = "Hi, " + profileName[0].toUpperCase() + profileName.toLowerCase().slice(1) +  `  <i id="acc-arrow" class="icon-arrow-right ic-arrow"></i>`;
        //profileOptions2.classList.remove('hide-options');
        //profileOptions.classList.remove('hide-options');
       
        this.setState( {islogged: true});


      }else{

        logInBtn.classList.remove("pd-btn");
        logInBtn2.classList.remove("pd-btn");
        
        this.setState( {islogged: false});

        logInBtn.classList.remove('js-rewards-btn');
        logInBtn2.classList.remove('js-rewards-btn');
        profileOptions.classList.add('hide-options');
        profileOptions2.classList.add('hide-options');
        logInBtn.innerHTML = `<i class="icon-sign-in"></i> SSG Sign up/Login`;
        logInBtn2.innerHTML = `<i class="icon-sign-in"></i> SSG Sign up/Login`;
      }
   
    })
    .catch(error => console.log(error));
  }


  togglePassword = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  };


  displayOptions = () =>{
    //Display profile options
    const giftToggle = document.querySelectorAll("#js-registry__toggle");
    const giftModal = document.querySelectorAll(".options__modal");

    const getStartedBtn = document.querySelectorAll(".get-started");
    //Gift Modal

  getStartedBtn.forEach((item) => {
    item.addEventListener("click", () => {
      document.getElementById("agree-popup").classList.remove("hide");
    });
  });
  
  function toggleGiftModal(index) {
    giftModal[index].classList.toggle("visible");
  }
  
  giftToggle.forEach((item, index) => {
    item.addEventListener("click", () => toggleGiftModal(index));
  });

  }

  render() {
    return (
      <Fragment>
      <a className="mn-content-btn js-mn-content-btn user-login">{this.state.islogged ? this.state.usertext : (
    <div>
      <i className="ic-sign icon-sign-in"></i> SSG Sign up/Login
    </div>
  )}<i></i></a>
      <div className="mn-content main-login">
      <div className={this.state.islogged == true ? "is-logged": "is-logged hide-login"} id="is-logged">
        <ProfileOptions  />
      </div>
        <div className={this.state.islogged == true ? "mn-rewards hide-login": "mn-rewards"}>
          <div className="mn-rewards-inner">
            <div className="row login">
              <div className="xs-12 center md-12 xl-6">
                <div className="login-container">
                  <h2 className="login-title">Sign In</h2>
                  <input
                    type="text"
                    className="login-email"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleChange}
                    placeholder="Email Address"
                  />
                  <div className="password-container">
                  <input
                    type={this.state.passwordShown ? "text" : "password"}
                    className="login-password"
                    name="password"
                    onChange={this.handleChange}
                    placeholder="Password"
                  />
                  <i onClick={this.togglePassword} className={this.state.passwordShown ? "icon-eye_password-hidden hidden-eye" : "icon-eye_password-shown hidden-eye"}>            
                  </i>
                  </div>
                  {this.state.errorMsg != "" &&
                  <span className="error-msg ">{!this.state.needRecaptcha ? this.state.errorMsg : ""}</span>
                   }
                  <div className="row ck-option">
                    <div className="xs-2">
                      <input
                        type="checkbox"
                        id="staySign"
                        value={this.state.staySignedIn}
                        onChange={this.handleChange}
                        name="staySignedIn"
                      />
                    </div>
                    <div className="xs-10">
                      <p>Stay signed in</p>
                    </div>
                  </div>
                  {this.state.needRecaptcha && 
                      <div className="xs-12 recaptcha-row">
                      <CustomRecaptchaLogin
                        wrapperClassName="xs-12 lg-12"
                        onTokenChange={this.handleRecaptchaTokenChange}
                      />
                    </div> }
                    {this.state.needRecaptcha == true &&
                     <span className="error-rcp ">{this.state.errorMsg}</span>
                   }
                  <button
                    className="btn-sign-in"
                    onClick={() => this.signInUser()}
                  >
                    Sign In To Account
                  </button>
                  <p className="link-recover" href="">
                    Recover <a href= '/my-account/forgot-username'>Username</a> or <a href= '/my-account/forgot-password'>Password</a>
                  </p>
                </div>
              </div>
              <div className="xs-12 center md-12 xl-6">
                <h2 className="login-title">Become A Member</h2>
                <p className="login-paragraph">
                  Unlock exclusive benefits by joining
                  Sandals Select Rewards.
                </p>
                <h3 className="login-subtitle">Already Booked? Access Your Account To:</h3>
                <ul className="login-details">
                  <li>Check In Online</li>
                  <li>View Trip Details</li>
                  <li>Track Your Reward Points</li>
                  <li>Book Holiday Extras</li>
                  <li>Refer Your Friends</li>
                  <li>And more!</li>
                </ul>
                <a href='/my-account/register' className="link-account">
                  Create Your Account{" "}
                  <i className="icon-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default AccountLogin;