// == Create links for the social
const SocialShare = {};

let ogDescription = $("meta[property='og:description']").attr('content');
let ogImg = $("meta[property='og:image']").attr('content');

if (ogDescription === undefined) {
  ogDescription = 'Beaches Resorts';
}

if (ogImg === undefined) {
  ogImg = '//cdn.sandals.com/beaches/v12/images/vacations/everyone/main-everyone.jpg';
}

SocialShare.doPopup = (event, href) => {
  const e = event || window.event;
  const width = 800;
  const height = 500;

  const px = Math.floor(((screen.availWidth || 1024) - width) / 2);
  const py = Math.floor(((screen.availHeight || 700) - height) / 2);

  const popup = window.open(href, 'social',
    'width=' + width + ',height=' + height +
    ',left=' + px + ',top=' + py +
    ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');

  if (popup) {
    popup.focus();
    // e.persist();

    if (e.preventDefault) {
      e.preventDefault();
    }

    e.returnValue = false;
  }

  return !!popup;
};

export const linkFacebook = 'https://www.facebook.com/sharer.php?s=100&p[url]=https://www.beaches.co.uk' + location.pathname + '&p[images][0]=' + ogImg + '&p[summary]=' + ogDescription;

$('.js-share-facebook').attr('href', linkFacebook);

export const linkPinterest = 'https://pinterest.com/pin/create/bookmarklet/?&url=https://www.beaches.co.uk' + location.pathname + '&media=' + ogImg + '&description=' + ogDescription;

$('.js-share-pinterest').attr('href', linkPinterest);

export const linkTwitter = 'https://twitter.com/intent/tweet?url=https://www.beaches.co.uk' + location.pathname + '&text=' + ogDescription;

$('.js-share-twitter').attr('href', linkTwitter);

export const linkGooglePlus = 'https://plus.google.com/share?url=https://www.beaches.co.uk' + location.pathname;

$('.js-share-googlePlus').attr('href', linkGooglePlus);

// == Create links for the Facebook
$('.js-share-facebook').on('click', (e) => {
  SocialShare.doPopup(e, linkFacebook);
});

// == Create links for the Pinterest
$('.js-share-pinterest').on('click', (e) => {
  SocialShare.doPopup(e, linkPinterest);
});

// == Create links for the twitter
$('.js-share-twitter').on('click', (e) => {
  SocialShare.doPopup(e, linkTwitter);
});

// == Create links for the googlePlus
$('.js-share-googlePlus').on('click', (e) => {
  SocialShare.doPopup(e, linkGooglePlus);
});

export default SocialShare;
