// // STOP IE11 and other old browsers from caching page state
window.addEventListener('pageshow', (event) => {
  if (event.persisted || window.performance && window.performance.navigation.type === 2) {
    window.location.reload(true);
  }
});

// Deps
import 'babel-polyfill';
import 'formdata-polyfill';
import 'whatwg-fetch';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

// Utilities
import _ from './modules/utils';

// Globals
import runGlobal from './modules/global';

// Assigning global variables to window object
Object.assign(window, { $, jQuery: $, React, ReactDOM, _ });

// Kickstart globals
runGlobal();

