// import $ from 'jquery';
// import '~/js/libs/magnific-popup';
import 'magnific-popup';
// import 'waypoints';

const modal = () => {
  // let scrollTop = 0;
  const popup = $('.js-magnific-popup');
  const videoPopup = $('.js-magnific-video');

  $(document).magnificPopup({
    delegate: '.js-magnific-popup',
    closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
    fixedContentPos: true,
    callbacks: {
      beforeOpen: function beforeOpenFunction() {
        // window.Waypoint.disableAll();
        $('body').toggleClass('has-modal');
        $(document).trigger('Modal.Fixed', true);
      },
      afterClose: function closeFunction() {
        // window.Waypoint.enableAll();
        $('body').toggleClass('has-modal');
        $(document).trigger('Modal.Fixed', false);
      }
    }
  });

  if(videoPopup.length) {
    $('.js-magnific-video').magnificPopup({
      closeMarkup: '<span class="mfp-close"></span>',
      type: 'iframe',
      mainClass: 'video-popup',
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com',
            id: 'v=',
            src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
          }
        }
      },
      fixedContentPos: true
    });
  }
};

export default modal;
