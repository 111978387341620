import { Component } from 'react';
import Cookie from '~/js/modules/Cookie';

export default class SubscribePopup extends Component {

  constructor() {
    super();
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const $window = $(window);
    const hideSubscribePopup = Cookie.has('HIDE_SUBSCRIBE_POPUP') ? Cookie.get('HIDE_SUBSCRIBE_POPUP') : 'false';
    const $footer = $('footer');
    const $subscribePopupElement = $('#subscribe-popup');

    if(hideSubscribePopup === 'true') {
      $('#subscribe-popup-box').remove();
    } else if($footer.length > 0) {
      const distanceTop = $footer.offset().top - $window.height();

      if ($window.scrollTop() > distanceTop) {
        $('#subscribe-popup-box').fadeIn();
        $subscribePopupElement.animate({ 'left': '0px' }, 400);
      } else {
        $subscribePopupElement.stop(true).animate({ 'left': '-600px' }, 400);
        $('#subscribe-popup-box').fadeOut();
      }
    } else if($window.scrollTop() + $window.height() > $(document).height() - 200) {
      $('#subscribe-popup-box').fadeIn();
      $subscribePopupElement.animate({ 'left': '0px' }, 400);
    } else {
      $subscribePopupElement.stop(true).animate({ 'left': '-600px' }, 400);
      $('#subscribe-popup-box').fadeOut();
    }
  }

  handleOnClick = () => {
    const subscribePopupElement = $('#subscribe-popup');

    this.setNewsletterCookie();
    subscribePopupElement.remove();
  }

  setNewsletterCookie = () => {
    // 60sec*60mins*24hours = 86400 (1 day), 5*60*1000(5mins)
    const d = new Date();

    d.setTime(d.getTime() + 86400000); // in milliseconds
    document.cookie = 'HIDE_SUBSCRIBE_POPUP=true;path=/;expires=' + d.toGMTString() + ';';
  }

  render() {
    let subscriptionMarkup;

    if ((window.location.href).indexOf('/sign-up/') === -1) {
      subscriptionMarkup = (
        <form className="flipper" id="subscribe-popup-box">
          <div className="circle-popup">
              <div className="circle-line">
                <div className="circle-img">
                  <div className="sign-up">sign-up</div>
                  <p>to our <span>newsletters</span></p>
                </div>
              </div>
              <div className="click-here">
                <a href="/sign-up/">CLICK HERE </a>
                <div className="circle">
                  <i className="icon-arrow-right">
                  </i>
                </div>
              </div>
            </div>
          <div className="close-btn close" onClick={this.handleOnClick} />
        </form>
      );
    } else {
      subscriptionMarkup = (
        <div className="hide" />
      );
    }

    return (
      subscriptionMarkup
    );
  }
}
