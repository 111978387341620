// import $ from 'jquery';
// import '~/js/libs/jquery.lazy';
// import '~/js/libs/bootstrap.min';
import React from "react";
import ReactDOM from "react-dom";
import '~/js/libs/bootstrap/index';
import 'jquery.scrollbar';
import '~/js/libs/jquery.waypoint';
// import '~/js/libs/sticky';
import SubscribePopup from '~/js/components/subscription/subscribePopup';
import '~/js/modules/header-search';
import '~/js/modules/ukqq';
import ProfileOptions from "./ProfileOptions";
import AccountLogin from "./AccountLogin";

const resortMenu = document.getElementById('resort-menu');

const rewardsMenu = document.getElementById("rewards-program");
const headerSignInBtn = document.getElementById("js-registry__toggle");


const accountLogin = document.querySelector(".rewards-program");

const profileOptions = document.querySelector(".profile-options");
const profileOptions2 = document.querySelector(".profile-options-2");


if(profileOptions && profileOptions.classList ){
  profileOptions.classList.remove('hide-options');
}


if(profileOptions2 && profileOptions2.classList ){
  profileOptions2.classList.remove('hide-options');
}

  ReactDOM.render(<AccountLogin />, accountLogin);
  ReactDOM.render(<ProfileOptions />, profileOptions);
  ReactDOM.render(<ProfileOptions />, profileOptions2);
  
  // Display profile options
  const giftToggle = document.querySelectorAll("#js-registry__toggle");
  const giftModal = document.querySelectorAll(".options__modal");
  
  const getStartedBtn = document.querySelectorAll(".get-started");
  //Gift Modal
  
    getStartedBtn.forEach((item) => {
      item.addEventListener("click", () => {
        document.getElementById("agree-popup").classList.remove("hide");
      });
    });
    
    function toggleGiftModal(index) {
      giftModal[index].classList.toggle("visible");
    }
    
    giftToggle.forEach((item, index) => {
      item.addEventListener("click", () => toggleGiftModal(index));
    });
  
  // Toggle arrow icon
  if(typeof headerSignInBtn !== 'undefined') {
    headerSignInBtn.addEventListener('click', () => {
      const icArrow = document.getElementById("acc-arrow");
      if(icArrow){
        icArrow.classList.toggle('open');
      }
    })
  }   

const header = () => {
  // const QuickQuote = require('~/js/components/global/QuickQuote').default; // QQTODO
  const $document = $(document);
  const $window = $(window);
  const $body = $('body');
  const $html = $('html');
  const $header = $('.js-header');
  const $main = $('.js-main').first();
  const $mainGroupsMenuTop = $('.js-main-group-menu-top');
  const $menuBtn = $('.js-menu-btn');
  const $rewardsBtn = $(".js-login-btn");
  const $rewardsBtn2 = $(".js-login-btn-2");
  const $resortsBtn = $('.js-resorts-btn');
  const $headerBottom = $('.js-header-bottom');
  const $mobileResorts = $('.js-mobile-resorts');
  // const $scroll = $('.js-scroll');
  const $mnContentBtn = $('.js-mn-content-btn');
  const $mnNavbarList = $('.js-mn-navbar-list');
  const $smNavbarList = $(".js-mn-navbar-sm-list");
  const $customSelects = $('.js-cr-select');
  // const quickQuoteElms = document.querySelectorAll('.mm-qq'); // QQTODO
  const $bookNowButton = $('.js-hm-book-now');
  const $bookNowOverlay = $('.js-hm-book-now-overlay');
  const $bookNowCloseBtn = $('.js-hm-book-now > .icon-close');
  const clickEventType = ((document.ontouchstart !== null) ? 'click' : 'touchstart');
  let savedWindowWidth = window.innerWidth;

  let isMenuOpen = false;
  let isResortsOpen = false;
  let isBookNowOpen = false;
  let scrollTop;

  // set cobrand navs
  const stickyNavs = ['resort-menu', 'nav'];
  let $currentNav = $();
  let stickyNavSelector = '';

  const hasCobrand = Object.keys(beaches_app.referral).length !== 0;
  const hasNav = () => $currentNav.length !== 0;

  const $form = $('form.combined_searchform');

  if ($('.page-qq:visible').length > 0) {
    $form.appendTo($('.page-qq:visible'));
  }
  

// https://sanserviceshn.atlassian.net/browse/BUW-1386
const $hiddenContent = $('.hidden-content');
const $toggleBtnOpenResorts = $('.toggle-btn');
const $overlayHiddenContent = $('.overlay-hidden-content');

$toggleBtnOpenResorts.on('click', () => {
  $toggleBtnOpenResorts.find('.chevron-sm').toggleClass('bottom');
  $toggleBtnOpenResorts.find('.icon-close').toggleClass('hide');
  $toggleBtnOpenResorts.find('.chevron-sm').toggleClass('hide');
  if (window.innerWidth < 768) {
    $toggleBtnOpenResorts.find('.icon-close').toggleClass('hide');
    $toggleBtnOpenResorts.find('.chevron-sm').toggleClass('hide');
  }
  $hiddenContent.toggleClass('show').focus();
  $overlayHiddenContent.toggleClass('show');
});

$hiddenContent.on('keydown', (e) => {

  if (e.keyCode === 27) {
    $toggleBtnOpenResorts.trigger('click');
  }
});

$overlayHiddenContent.on('click', () => {
  $toggleBtnOpenResorts.trigger('click');
});

// ENDS - https://sanserviceshn.atlassian.net/browse/BUW-1386

  // Set padding-top for thomas banner active
  // $body.addClass('thomas_banner_active'); 

  // look for sticky nav
  const setCurrentNav = () => {
    if (hasCobrand) {
      stickyNavs.find(navSelector => {
        $currentNav = $('#' + navSelector);
        stickyNavSelector = navSelector;

        return hasNav();
      });
    }
  };

  // get top of elem
  const getTop = (elem) => {
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTopNav = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;
    const top = box.top + scrollTopNav - clientTop;

    return Math.round(top);
  };

  // if has cobrand add paddings
  if (hasCobrand) {
    const body = document.getElementsByTagName('body')[0];
    const cobrandPaddingElement = document.createElement('div');

    // add padding to main page
    cobrandPaddingElement.classList.add('js-cobrand-padding');
    cobrandPaddingElement.style.height = $('.js-cobrand').height() + 'px';
    body.insertBefore(cobrandPaddingElement, body.firstChild);

    setCurrentNav();
    if (hasNav()) {
      const navInitialTop = getTop(document.getElementById(stickyNavSelector)) < 158
        ? ' nav-top'
        : '';

      $currentNav.addClass('has-cobrand' + navInitialTop);
    }
  }

  // toggle stuck header with cobrand
  const toggleStuckCobrand = (hasNavTop) => {
    setCurrentNav();
    if (!hasNav()) {
      return;
    }
    if ($currentNav.hasClass('nav-top') === hasNavTop) {
      return;
    }
    $currentNav.toggleClass('stuck-cobrand');
  };
  // end of cobrand

  // scrollbar measurement
  const scrollDiv = document.createElement('div');

  scrollDiv.className = 'scrollbar-measure';
  document.body.appendChild(scrollDiv);

  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  document.body.removeChild(scrollDiv);
  // scrollbar measurement END


  // Commented because they would get stuck in Beaches Negril Page 
  
  const main = $main.waypoint({
    handler: () => {
      $header.toggleClass('header--stuck');
      $body.toggleClass('has-stuck-header');
      toggleStuckCobrand(false);
    },
    offset: 0
  });

  $mainGroupsMenuTop.waypoint({
    handler: () => {
      $header.toggleClass('header--stuck');
      $body.toggleClass('has-stuck-header');
      toggleStuckCobrand(false);
    },
    offset: 54
  });

  $currentNav.waypoint({
    handler: () => {
      toggleStuckCobrand(true);
    },
    offset: 38
  });

  // main menu
  $menuBtn.on('click', function clickMenuBtn(e) {
    //  console.log('entro');
    e.preventDefault();

    const $this = $(this);
    // const $images = $headerBottom.find('[data-src]');
    const $allLink = $mnNavbarList.find('.mn-navbar-link');
    const $allBlock = $('.js-mn-content-wrap');

    isMenuOpen = !isMenuOpen;

    // $images.Lazy();

    $allLink.removeClass('mn-navbar-link--active');
    $allBlock.removeClass('mn-content-wrap--open');
    $($($allLink)[0]).addClass('mn-navbar-link--active');
    $($($allBlock)[0]).addClass('mn-content-wrap--open');

    if ($(window).width() <= 767) {
      $('.js-mn-content-btn').parent().find('.mn-content').hide();
      // $('.js-mn-content-btn').parent('.mn-content-wrap--open-mb').find('.mn-content').hide();
    } else {
      $('.js-mn-content-btn').parent().find('.mn-content').fadeIn();
    }

    $allBlock.removeClass('mn-content-wrap--open-mb');

    $resortsBtn.removeClass('hm-resorts-btn--active');
    $mobileResorts.removeClass('mobile-resorts--open');
    $body.removeClass('has-open-resorts');
    $html.removeClass('has-open-resorts');

    $this.toggleClass('hm-menu-btn--active');
    $headerBottom.toggleClass('header-bottom--open');

    if (isMenuOpen) {
      main[0].disable();

      $body.css({ 'margin-right': scrollbarWidth });
      $header.css({
        'left': '-' + scrollbarWidth + 'px'
      });
      resortMenu && (resortMenu.style.left = `-${scrollbarWidth}px`);

      scrollTop = !isResortsOpen
        ? window.scrollY
        : 0;
      isResortsOpen = false;
      $body.toggleClass('has-open-menu');
      $html.toggleClass('has-open-menu');
      if ($(window).width() <= 767) {
        if ($('.mobile-qq').find('form.combined_searchform').length < 1) {
          $form.appendTo($('.mobile-qq'));
        }
      }
    } else {
      $body.css({ 'margin-right': 0 });
      $header.css({ 'left': 0 });
      resortMenu && (resortMenu.style.left = null);
      $body.toggleClass('has-open-menu');
      $html.toggleClass('has-open-menu');
      $window.scrollTop(scrollTop);
      if ($(window).width() <= 767) {
        if ($('.page-qq:visible').find('form.combined_searchform').length < 1) {
          $form.appendTo($('.page-qq:visible'));
        }
      }
      main[0].enable();
    }

  });
  // main menu END

  
  $rewardsBtn
  .on("click", function clickMenuBtn(e) {

    
    //profileOptions.classList.remove('hide-options');
    const $allLink = $mnNavbarList.find(".mn-navbar-link");

    $allLink.removeClass("mn-navbar-link--active");

    if (!headerSignInBtn.classList.contains("js-rewards-btn")) {
  
      e.preventDefault();
      const $this = $(this);
      const $mainMenu = $(".hm-menu-btn");

      // const $images = $headerBottom.find('[data-src]');
      const $allSmLink = $smNavbarList.find(".sm-navbar");
      const $allBlock = $(".js-mn-content-wrap");
      const isExpanded =
        e.currentTarget.getAttribute("aria-expanded") === "true";

      e.currentTarget.setAttribute("aria-expanded", `${!isExpanded}`);

      isMenuOpen = !isMenuOpen;
      // $images.Lazy();

      $allSmLink.removeClass("sm-navbar-link--active");
      $allBlock.removeClass("mn-content-wrap--open");

      $($($allBlock)[5]).addClass("mn-content-wrap--open");

      if ($(window).width() <= 767) {
        $(".js-mn-content-btn").parent().find(".mn-content").hide();
        // $('.js-mn-content-btn').parent('.mn-content-wrap--open-mb').find('.mn-content').hide();
      } else {
        $(".js-mn-content-btn").parent().find(".mn-content").fadeIn();
      }

      $allBlock.removeClass("mn-content-wrap--open-mb");

      $mobileResorts.removeClass("mobile-resorts--open");
      $body.removeClass("has-open-resorts");
      $html.removeClass("has-open-resorts");


      $mainMenu.toggleClass("hm-menu-btn--active");
      $headerBottom.toggleClass("header-bottom--open");

      if (isMenuOpen) {
        main[0].disable();

        $body.css({ "margin-right": scrollbarWidth });
        $header.css({
          left: "-" + scrollbarWidth + "px",
        });

        rewardsMenu && (rewardsMenu.style.left = `-${scrollbarWidth}px`);

        scrollTop = !isResortsOpen ? window.scrollY : 0;
        isResortsOpen = false;
        $body.toggleClass("has-open-menu");
        $html.toggleClass("has-open-menu");
      } else {
        $body.css({ "margin-right": 0 });
        $header.css({ left: 0 });
        rewardsMenu && (rewardsMenu.style.left = null);
        $body.toggleClass("has-open-menu");
        $html.toggleClass("has-open-menu");
        $window.scrollTop(scrollTop);

        main[0].enable();
      }
    }
  })
  .on("keydown", (e) => {
    /**
     * KeyCodes
     * spacebar -> 32
     * enter -> 13
     * escape -> 27
     *
     * Handle user interactions with hamburger menu button
     */

    const { keyCode } = e.originalEvent;

    if (keyCode === 13 || keyCode === 32) {
      e.preventDefault();

      $mainMenu.toggleClass("hm-menu-btn--active");
      $headerBottom.toggleClass("header-bottom--open");
      isMenuOpen = !isMenuOpen;
    }

    if (keyCode === 27 && $menuBtn.hasClass("hm-menu-btn--active")) {
      $mainMenu.toggleClass("hm-menu-btn--active");
      $headerBottom.toggleClass("header-bottom--open");
      isMenuOpen = false;
    }
  });
// sign in button END

  //second sign in button 
  $rewardsBtn2
  .on("click", function clickMenuBtn(e) {

    const $allLink = $mnNavbarList.find(".mn-navbar-link");

    $allLink.removeClass("mn-navbar-link--active");

    if (!headerSignInBtn.classList.contains("js-rewards-btn")) {
  
      e.preventDefault();
      const $this = $(this);
      const $mainMenu = $(".hm-menu-btn");

      // const $images = $headerBottom.find('[data-src]');
      const $allSmLink = $smNavbarList.find(".sm-navbar");
      const $allBlock = $(".js-mn-content-wrap");
      const isExpanded =
        e.currentTarget.getAttribute("aria-expanded") === "true";

      e.currentTarget.setAttribute("aria-expanded", `${!isExpanded}`);

      isMenuOpen = !isMenuOpen;
      // $images.Lazy();

      $allSmLink.removeClass("sm-navbar-link--active");
      $allBlock.removeClass("mn-content-wrap--open");

      $($($allBlock)[5]).addClass("mn-content-wrap--open");

      if ($(window).width() <= 767) {
        $(".js-mn-content-btn").parent().find(".mn-content").hide();
        // $('.js-mn-content-btn').parent('.mn-content-wrap--open-mb').find('.mn-content').hide();
      } else {
        $(".js-mn-content-btn").parent().find(".mn-content").fadeIn();
      }

      $allBlock.removeClass("mn-content-wrap--open-mb");

      $mobileResorts.removeClass("mobile-resorts--open");
      $body.removeClass("has-open-resorts");
      $html.removeClass("has-open-resorts");


      $mainMenu.toggleClass("hm-menu-btn--active");
      $headerBottom.toggleClass("header-bottom--open");

      if (isMenuOpen) {
        main[0].disable();

        $body.css({ "margin-right": scrollbarWidth });
        $header.css({
          left: "-" + scrollbarWidth + "px",
        });

        rewardsMenu && (rewardsMenu.style.left = `-${scrollbarWidth}px`);

        scrollTop = !isResortsOpen ? window.scrollY : 0;
        isResortsOpen = false;
        $body.toggleClass("has-open-menu");
        $html.toggleClass("has-open-menu");
      } else {
        $body.css({ "margin-right": 0 });
        $header.css({ left: 0 });
        rewardsMenu && (rewardsMenu.style.left = null);
        $body.toggleClass("has-open-menu");
        $html.toggleClass("has-open-menu");
        $window.scrollTop(scrollTop);

        main[0].enable();
      }
    }
  })
  .on("keydown", (e) => {
    /**
     * KeyCodes
     * spacebar -> 32
     * enter -> 13
     * escape -> 27
     *
     * Handle user interactions with hamburger menu button
     */

    const { keyCode } = e.originalEvent;

    if (keyCode === 13 || keyCode === 32) {
      e.preventDefault();

      $mainMenu.toggleClass("hm-menu-btn--active");
      $headerBottom.toggleClass("header-bottom--open");
      isMenuOpen = !isMenuOpen;
    }

    if (keyCode === 27 && $menuBtn.hasClass("hm-menu-btn--active")) {
      $mainMenu.toggleClass("hm-menu-btn--active");
      $headerBottom.toggleClass("header-bottom--open");
      isMenuOpen = false;
    }
  });
// sign in button END

  // hide a main menu when the user clicks outside of it
  if (window.innerWidth > 1200) {
    $document.on('click touchstart', (e) => {
      if (!$header.is(e.target) && $header.has(e.target).length === 0 && isMenuOpen) {
        $menuBtn.trigger('click');
      }
    });
  }
  // hide a main menu when the user clicks outside of it END

  // resorts menu
  $resortsBtn.on('click', function clickResortsBtn(e) {
    e.preventDefault();

    const $this = $(this);
    // const $images = $mobileResorts.find('[data-src]');

    isResortsOpen = !isResortsOpen;
    isMenuOpen = false;

    // $images.Lazy();

    $menuBtn.removeClass('hm-menu-btn--active');
    $headerBottom.removeClass('header-bottom--open');
    $body.removeClass('has-open-menu');
    $html.removeClass('has-open-menu');

    $this.toggleClass('hm-resorts-btn--active');
    $mobileResorts.toggleClass('mobile-resorts--open');
    $body.toggleClass('has-open-resorts');
    $html.toggleClass('has-open-resorts');

    if (isResortsOpen) {
      scrollTop = $window.scrollTop();
      if ($(window).width() <= 767) {
        if ($('.resort-qq').find('form.combined_searchform').length < 1) {
          $form.appendTo($('.resort-qq'));
        }
      }
    } else {
      $window.scrollTop(scrollTop);
      if ($(window).width() <= 767) {
        if ($('.page-qq:visible').find('form.combined_searchform').length < 1) {
          $form.appendTo($('.page-qq:visible'));
        }
      }
    }
  });
  // resorts menu END

  // mobile accordion
  $mnContentBtn.on('click', function clickContentBtn(e) {
    e.preventDefault();

    const $this = $(this);
    const $parent = $this.parent();

    $parent.toggleClass('mn-content-wrap--open-mb');
    $parent.find('.mn-content').slideToggle(400);
  });
  // mobile accordion END

  // desktop accordion
  let clickMouseEnter = 'mouseenter click';
  let isDesktop = true;

  if (window.innerWidth < 992) {
    clickMouseEnter = 'click';
    isDesktop = false;
  }

  $mnNavbarList.on(clickMouseEnter, '.mn-navbar-link', function clickNavbarList(e) {

    if (isDesktop && e.type === 'click') {
      // const link = e.currentTarget.dataset.link;
      // window.location = link;
    } else {
      e.preventDefault();

      const $this = $(this);
      const $allBlock = $('.js-mn-content-wrap');
      const $allLink = $mnNavbarList.find('.mn-navbar-link');
      let $block;

      if ($(e.target).is('a')) {
        $block = $($(e.target).attr('data-link'));
      } else {
        $block = $($(e.target).parents('a').attr('data-link'));
      }

      $allLink.removeClass('mn-navbar-link--active');
      $this.addClass('mn-navbar-link--active');
      $allBlock.removeClass('mn-content-wrap--open');
      $block.addClass('mn-content-wrap--open');
    }
  });
  // desktop accordion END

  // custom select
  $customSelects.each(function eachCustomSelects(key, item) {
    $(item).selectmenu({ appendTo: $(this).parents('.check-rates') });
  });

  // Quick Quote QQTODO
  $bookNowButton.on('click', () => {
    const $menuCont = $('.header-bottom ');

    $bookNowButton.addClass('active');

    if ($bookNowButton.hasClass('active')) {
      isBookNowOpen = true;
    } else {
      isBookNowOpen = false;
    }

    if (isBookNowOpen && $('.main-qq').find('form.combined_searchform').length < 1) {
      $form.appendTo($('.main-qq'));
    }

    if (!isBookNowOpen) {
      $form.appendTo($('.page-qq:visible'));
    }

    $menuBtn.removeClass('hm-menu-btn--active');
    $menuCont.removeClass('js-header-bottom header-bottom--open');
    $html.removeClass('has-open-menu');
    $body.removeClass('has-open-menu').css({ 'margin-right': 0 });
    $header.css({ 'left': 0 });
    isMenuOpen = false;
    main[0].enable();
  });

  $bookNowButton.on('mouseover', () => {
    $bookNowButton.addClass('hovered');
  });
  $bookNowButton.on('mouseleave', () => {
    $bookNowButton.removeClass('hovered');
  });

  $bookNowOverlay.on('click', () => {
    if ($('.page-qq:visible').length > 0) {
      $form.appendTo($('.page-qq:visible'));
    }
    $bookNowButton.removeClass('active');
    isBookNowOpen = false;
  });

  $bookNowCloseBtn.on('click', (e) => {
    e.stopImmediatePropagation();
    isBookNowOpen = false;

    if ($('.page-qq:visible').length > 0) {
      $form.appendTo($('.page-qq:visible'));
    }

    $bookNowButton.removeClass('active');
    $bookNowButton.removeClass('hovered');
  });

  // for (let index = 0; index < quickQuoteElms.length; index++) {
  //   ReactDOM.render(
  //     <QuickQuote
  //       className="qq-rst-menu"
  //       quoteButtonText="Get Quote"
  //       controlThemeClassName="control-small dark-side"
  //       brand="beaches"
  //     />,
  //     quickQuoteElms[index]
  //   );
  // }

  //  LANGUAGE
  class ShowLanguagePanel {
    constructor(langBtnClass, langBlockClass) {
      this.langBtnClass = langBtnClass;
      this.langBlockClass = langBlockClass;
      this.langBtn = document.querySelector(`.${langBtnClass}`);
      this.langBlock = document.querySelector(`.${langBlockClass}`);
      this.btnIcon = this.langBtn.querySelector('i');
      this._events();
    }

    _events() {
      this.langBtn.addEventListener('click', e => {
        e.preventDefault();
        this.langBlock.classList.toggle(`${this.langBlockClass}--show`);
        // if(window.innerWidth < 767) {
        this.btnIcon.classList.toggle('icon-close');
        this.btnIcon.classList.toggle('icon-globe');
        // }
      });

      window.addEventListener('resize', _.debounce(() => {
        this.langBlock.classList.remove(`${this.langBlockClass}--show`);
        this.btnIcon.classList.remove('icon-close');
        this.btnIcon.classList.add('icon-globe');
        /*
        * without this, if menu is scrolled in mobile and resized to other
        * breakpoints, the menu remains scrolled and won't be displayed properly
        */
        if (window.innerWidth > 767) {
          $headerBottom.scrollTop(0);
          $header.css('left', '0px');
        }
        if (window.innerWidth > 767) {
          $mobileResorts.removeClass('mobile-resorts--open');
          $resortsBtn.removeClass('hm-resorts-btn--active');
          isResortsOpen = false;
          $body.removeClass('has-open-resorts');
          $html.removeClass('has-open-resorts');
        }
      }, 200));

    }
  }


  $(window).scroll(() => {
    if ($('body').hasClass('has-stuck-header')) {
      if ($bookNowButton.hasClass('active')) {
        if ($('.page-qq:visible').length > 0) {
          $form.appendTo($('.page-qq:visible'));
        }
        $bookNowButton.removeClass('active');
        isBookNowOpen = false;
      }
    }
  });

  $(window).on('resize', () => {
    const windowWidth = window.innerWidth;

    if (savedWindowWidth === windowWidth) {

      return;
    }

    if (savedWindowWidth !== windowWidth) {

      savedWindowWidth = windowWidth;

      // if ($(document.activeElement).prop('type') !== 'text') {
      if (isResortsOpen) {
        // scrollTop = $window.scrollTop();
        if ($(window).width() <= 767) {
          if ($('.resort-qq').find('form.combined_searchform').length < 1) {
            $form.appendTo($('.resort-qq'));
          }
        } else {
          if ($('.page-qq:visible').length > 0) {
            if ($('.page-qq:visible').find('form.combined_searchform').length < 1) {
              $form.appendTo($('.page-qq:visible'));
            }
          }
        }
      } else if (isMenuOpen) {
        if ($(window).width() <= 767) {
          if ($('.mobile-qq').find('form.combined_searchform').length < 1) {
            $form.appendTo($('.mobile-qq'));
          }
        } else {
          if ($('.page-qq:visible').length > 0) {
            if ($('.page-qq:visible').find('form.combined_searchform').length < 1) {
              $form.appendTo($('.page-qq:visible'));
            }
          }
        }
      } else if (isBookNowOpen) {
        if ($(window).width() > 767) {
          if ($('.main-qq').find('form.combined_searchform').length < 1) {
            $form.appendTo($('.main-qq'));
          }
        }
      } else if ($('.page-qq:visible').length > 0) {
        if ($('.page-qq:visible').find('form.combined_searchform').length < 1) {
          $form.appendTo($('.page-qq:visible'));
        }
      }
    }

  });

  document.body.addEventListener(clickEventType, e => {
    const langBtn = document.querySelector('.js-lang-btn');
    const langBtn2 = document.querySelector('.js-lang-btn-turnoff');

    if (e.target !== langBtn && e.target !== langBtn2 && document.querySelector('.ht-country-list--show')) {
      if ($(e.target).closest('.ht-country-list')) {
        document.querySelector('.ht-country-list').classList.remove('ht-country-list--show');
        langBtn.querySelector('i').className = 'ic icon-globe';
        langBtn2.querySelector('i').className = 'ic icon-globe';
      }
    }
  });

  document.querySelector('.js-lang-btn') && new ShowLanguagePanel('js-lang-btn', 'ht-country-list');
  document.querySelector('.js-lang-btn-mobile') && new ShowLanguagePanel('js-lang-btn-mobile', 'ht-country-list-mobile');
  document.querySelector('.js-lang-btn-turnoff') && new ShowLanguagePanel('js-lang-btn-turnoff', 'ht-country-list');

  // Subscribe Newsletter Popup Component
  ReactDOM.render(
    <SubscribePopup />,
    document.getElementById('subscribe-popup')
  );
  
  // Close button for Thomas Banner
    document.body.addEventListener('click', e => {
        if (e.target.id === "close_thomas_banner") {
            $('#thomas-cook-banner').addClass('hide');
            $body.removeClass('thomas_banner_active'); 
            e.stopPropagation();
        }
    });
};

export default header;
