/* eslint-disable */
import '~/js/libs/chosenjquerymin';
import '~/js/libs/jqdeserialize';
import '~/js/libs/fancybox';
import '~/js/libs/flatpickr-248';
import moment from '~/js/libs/moment-with-locales-2.17.1';
import Cookies from '~/js/libs/jscookie220';

$(document).ready(function () {
  var closedBy = null;
  var flatpickr = null;
  var hasValueAlready = false;
  var initDate = '';


  $(".select select").chosen({
    width: "100%",
    disable_search: true
  });


  $(document).on("focus", ":input", function (event) {
    setTimeout(() => {
      if ($('.overlay__div').is(':visible')) {
        event.target.blur();
      }
    }, 10);
  });

  if (beaches_app.page.rstCode !== undefined) {
    var defVal = '';
    var defIata = '';
    $('form.combined_searchform #hotelcode option').each(function () {
      if (beaches_app.page.rstCode == $(this).data('code')) {
        defVal = $(this).attr('value');
        defIata = $(this).attr('iata');
      }
    });
    $('form.combined_searchform #hotelcode').val(defVal).trigger('chosen:updated');
    $('form.combined_searchform').find('#destair').val(defIata);
    $('form.combined_searchform').find('#destinationiata').val(defIata);
  }

  var picker = $(".flatpickr").flatpickr({
    mode: 'range',
    dateFormat: "j F y",
    minDate: new Date(),
    appendTo: $('.overlay__div').find('.overlayDatepicker')[0],
    closeOnSelect: false,
    // ignoredFocusElements: window.document.body,
    onOpen: function () {
      if ($('.overlay__div').css('display') != 'block') {
        $('.overlay__div').slideToggle("fast");
      }
      $('body').addClass('has-open-qqcal');
      if ($('form.combined_searchform #dateinput').val() !== "") {
        $('.overlaymessage__applybutton').hide();
        initDate = $('form.combined_searchform #dateinput').val();
        hasValueAlready = true;
      } else {
        hasValueAlready = false;
      }

    },
    onChange: datePickerOnClose,
    animate: false
  });

  $(document).keyup(function (e) {
    if (e.key === "Escape" && $('body').hasClass('has-open-qqcal')) {
      $('.overlay__div-closebutton').trigger('click');
    }
  });

  $('.overlay__div .headerwithicon').on("click", function (event) {
    console.log(' ');
  });

  $('.overlay__div-closebutton').on("click", function (event) {
    event.preventDefault();
    $('.overlay__div').slideUp("fast");

    closedBy = 'closebutton';
    if (!hasValueAlready) {
      $('form.combined_searchform #dateinput').val('');
      picker.clear();
      $('#nightscalendar').val('');
    } else {
      if (initDate !== $('form.combined_searchform #dateinput').val()) {
        $('form.combined_searchform #dateinput').val(initDate);
        picker.setDate(initDate);
        // picker.clear();
        // $('#nightscalendar').val('');
      }
    }
    $('.overlaymessage__applybutton').hide();
    $('body').removeClass('has-open-qqcal');
    picker.close();
  });

  $('.button__applybutton').on("click", function (event) {
    event.preventDefault();
    $('.overlay__div').slideUp("fast");

    closedBy = 'closebutton';
    $('body').removeClass('has-open-qqcal');
    picker.close();
  });

  function datePickerOnClose(selectedDates, dateStr, instance) {
    if (!selectedDates.length) {
      if (closedBy == 'closebutton') {
        closedBy = null;
      } else {
        // picker.open();
      }
      return;
    }

    if (closedBy == 'closebutton') {
      closedBy = null;
    } else {
      // picker.open();
    }

    if (selectedDates.length == 2) {
      var dates = dateStr.split(" to ", 2);
      var formatteddepartdate = moment(dates[0], 'DD MMMM YYYY').format('YYYY-MM-DD');
      var formattedreturndate = moment(dates[1], 'DD MMMM YYYY').format('YYYY-MM-DD');
      var nights = moment(formattedreturndate).diff(moment(formatteddepartdate), 'days');

      if (nights > 0) {

        $('#depdate').val(formatteddepartdate);
        $('#startdate').val(formatteddepartdate);
        $('#enddate').val(formattedreturndate);

        var departdate = formatteddepartdate.split("-", 3);
        var returndate = formattedreturndate.split("-", 3);

        $('#startdate-year').val(departdate[0]);
        $('#startdate-month').val(departdate[1]);
        $('#startdate-day').val(departdate[2]);
        $('#depdate-year').val(departdate[0]);
        $('#depdate-month').val(departdate[1]);
        $('#depdate-day').val(departdate[2]);
        $('#enddate-year').val(returndate[0]);
        $('#enddate-month').val(returndate[1]);
        $('#enddate-day').val(returndate[2]);

        $('#nights').val(nights);
        $('#nights').trigger('chosen:updated');

        $('#nightscalendar').val(moment(formatteddepartdate).format('Do MMM YY') + ' - ' + moment(formattedreturndate).format('Do MMM YY') + ', ' + nights + ' Nights');
        $('.overlaymessage__applybutton').show();
      }
    }
  }


  var hotelcodemapping = {
    '37960': 'MBJ,KIN',
    '37958': 'MBJ,KIN',
    '37925': 'PLS',
    '37954': 'ANU',
    '37926': 'NAS',
    '37947': 'GGT',
    '66937': 'BGI',
    '75244': 'BGI',
    '64572': 'GND',
    '37928': 'MBJ,KIN',
    '37963': 'MBJ,KIN',
    '37950': 'MBJ,KIN',
    '37949': 'MBJ,KIN',
    '37953': 'MBJ,KIN',
    '37951': 'MBJ,KIN',
    '37948': 'MBJ,KIN',
    '37956': 'UVF',
    '37955': 'UVF',
    '37957': 'UVF',
  };

  if (document.getElementById("hotelcode")) {
    document.getElementById("hotelcode").onchange = function () {
      document.getElementById("destair").value = hotelcodemapping[this.value];
      document.getElementById("destinationiata").value = hotelcodemapping[this.value];
    };
  }

  if (document.getElementById("depart")) {
    document.getElementById("depart").onchange = function () {
      document.getElementById("originiata").value = this.value;
      document.getElementById("depair").value = this.value;
    };
  }


  $("input:radio[name='roomgroups']").change(function () {
    var roomcount = $("input[name='roomgroups']:checked").val();
    $('#roomcount').val(roomcount);
    $('#adults').val(roomcount * 2);
  });

  $('#combined_searchform').off('submit').on('submit', function (event) {
    event.preventDefault();

    var form = $(event.currentTarget);
    var totalpax = {
      adults: 0,
      children: 0,
      infants: 0
    };

    form.find('.room:visible').each(function () {
      var room = $(this);

      totalpax.adults += (room.find('.adultcount').val() * 1);
      totalpax.children += (room.find('.childcount').val() * 1);
      totalpax.infants += (room.find('.infantcount').val() * 1);
    });

    for (var type in totalpax) {
      form.find('#' + type).val(totalpax[type]);
    }
  });

  $('[data-type="plus"]').on('click', function (event) {
    var field = $(this).data('field');
    var searchoption = $(this).parents('.searchoption');

    var adults = parseInt(searchoption.find('.adultcount').val());
    var children = parseInt(searchoption.find('.childcount').val());
    var infants = parseInt(searchoption.find('.infantcount').val());

    var total = adults + children + infants;

    if (field.indexOf('adults') != -1) {
      if (total > 7) {
        event.stopImmediatePropagation();
      }
    }

    if (field.indexOf('children') != -1) {

      if (total > 7) {
        event.stopImmediatePropagation();
        return;
      }

      if ((children + infants) > 3) {
        event.stopImmediatePropagation();
      }
    }

    if (field.indexOf('infants') != -1) {

      if (total > 7) {
        event.stopImmediatePropagation();
        return;
      }

      if ((children + infants) > 3) {
        event.stopImmediatePropagation();
      }
    }
  });

  var minmessage = "Input is below the minimum.";
  var maxmessage = "Input is above the maximum.";

  $(".btn-number").off('click').on('click', function (a) {
    a.preventDefault(); var fieldName = $(this).attr("data-field"), type = $(this).attr("data-type");
    var t = $("input[name='" + fieldName + "']"),
      n = parseInt(t.val());
    isNaN(n) ? t.val(0) : "minus" == type ? (n > t.attr("min") && t.val(n - 1).change(), parseInt(t.val()) == t.attr("min") && $(this).attr("disabled", !0)) : "plus" == type && (n < t.attr("max") && t.val(n + 1).change(), parseInt(t.val()) == t.attr("max") && $(this).attr("disabled", !0))
  }), $(".input-number").focusin(function () {
    $(this).data("oldValue", $(this).val())
  }), $(".input-number").change(function () {
    var minValue = parseInt($(this).attr("min")), maxValue = parseInt($(this).attr("max")), valueCurrent = parseInt($(this).val()), name = $(this).attr("name");
    valueCurrent >= minValue ? $(".btn-number[data-type='minus'][data-field='" + name + "']").removeAttr("disabled") : (alert(minmessage), $(this).val($(this).data("oldValue"))), valueCurrent <= maxValue ? $(".btn-number[data-type='plus'][data-field='" + name + "']").removeAttr("disabled") : (alert(maxmessage), $(this).val($(this).data("oldValue")))
  }), $(".input-number").keydown(function (a) {
    -1 !== $.inArray(a.keyCode, [46, 8, 9, 27, 13, 190]) || 65 == a.keyCode && a.ctrlKey === !0 || a.keyCode >= 35 && a.keyCode <= 39 || (a.shiftKey || a.keyCode < 48 || a.keyCode > 57) && (a.keyCode < 96 || a.keyCode > 105) && a.preventDefault()
  });

  $('.btn-number').each(function () {
    var fieldName = $(this).attr("data-field"), type = $(this).attr("data-type");
    var t = $("input[name='" + fieldName + "']"),
      n = parseInt(t.val());
    if ("minus" == type) {
      if (n == t.attr("min")) {
        $(this).prop('disabled', true);
      } else {
        $(this).prop('disabled', false);
      }
    } else if ("plus" == type) {
      if (n == t.attr("max")) {
        $(this).prop('disabled', true);
      } else {
        $(this).prop('disabled', false);
      }
    }
  });

  $('.searchtabs button').on('click', function (event) {
    var button = $(this);

    if (button.hasClass('active')) {
      return;
    }

    if (button.hasClass('button__searchtype--flighthotel')) {

      $('.button__searchtype--flighthotel').addClass('active');
      $('.button__searchtype--hotelonly').removeClass('active');
      $('#product-flight').attr('disabled', false);
      $('#product-hotel').attr('disabled', false);
      $('#searchtype').val('flighthotel');
      $('#product').val('multisearch');
      $('#multisearch').val('1');
      $('#redirecturl').val('/resorts');
      $('.searchoption__departfield').slideToggle();
      $('.searchoption__flightclass').animate({ width: 'toggle' }, '50%');
      $('.searchoption__promocode').toggleClass("searchoption__promocode-slideleft", 1000, "easeOutSine");

    } else {

      $('.button__searchtype--flighthotel').removeClass('active');
      $('.button__searchtype--hotelonly').addClass('active');

      $('.searchoption__departfield').slideToggle();
      $('.searchoption__flightclass').animate({ width: 'toggle' }, 0);
      $('.searchoption__promocode').toggleClass("searchoption__promocode-slideleft", 1000, "easeOutSine");

      $('#product-flight').attr('disabled', true);
      $('#product-hotel').attr('disabled', true);
      $('#searchtype').val('hotel');
      $('#product').val('hotel');
      $('#multisearch').val('0');
      $('#redirecturl').val('/resort');
      $('#depair').val('');
    }

  });

  $('.searchtabs__agent button').on('click', function (event) {
    var button = $(this);

    if (button.hasClass('active')) {
      return;
    }

    if (button.hasClass('button__searchtype--flighthotel')) {

      $('.button__searchtype--flighthotel').addClass('active');
      $('.button__searchtype--hotelonly').removeClass('active');
      $('#product-flight').attr('disabled', false);
      $('#product-hotel').attr('disabled', false);
      $('#searchtype').val('flighthotel');
      $('#product').val('multisearch');
      $('#multisearch').val('1');
      $('#redirecturl').val('/resorts-agent');
      $('.searchoption__departfield').slideToggle();
      $('.searchoption__flightclass').animate({ width: 'toggle' }, '50%');
      $('.searchoption__promocode').toggleClass("searchoption__promocode-slideleft", 1000, "easeOutSine");

    } else {

      $('.button__searchtype--flighthotel').removeClass('active');
      $('.button__searchtype--hotelonly').addClass('active');

      $('.searchoption__departfield').slideToggle();
      $('.searchoption__flightclass').animate({ width: 'toggle' }, 0);
      $('.searchoption__promocode').toggleClass("searchoption__promocode-slideleft", 1000, "easeOutSine");

      $('#product-flight').attr('disabled', true);
      $('#product-hotel').attr('disabled', true);
      $('#searchtype').val('hotel');
      $('#product').val('hotel');
      $('#multisearch').val('0');
      $('#redirecturl').val('/resort-agent');
      $('#depair').val('');
    }

  });

  initsearchform('#combined_searchform', true);

});
// end document.ready

function roomchange(rooms) {
  $('#roomcount').val(rooms);
  $('.room').hide();

  for (var i = 0; i < rooms; i++) {
    $('#room-' + (i + 1)).show();
  }
}

$('input[name="flight-searchtype"]').on("change", function () {
  $('#multisearch').val($("input[name='flight-searchtype']:checked").val());
  $('.searchoption__additionaldest--first').slideToggle();
  if ($('#multicentre:checked').length) {
    if ($('.add-destination--first').css("display", "none")) {
      $('.add-destination--first').slideDown();
    }
  } else {
    $('.searchoption__additionaldest').each(function () {
      $(this).slideUp();
    });
  }
});

// 'Add Destination' Toggler on searchform
$('.add-destination--first').on("click", function () {
  $('.searchoption__additionaldest--last').slideToggle();
  $(this).slideToggle();
});
$('.add-destination--last').on("click", function () {
  $('.searchoption__additionaldest--last').slideToggle();
  $('.add-destination--first').slideToggle();
});


function isInt(a) {
  return a === "" + ~~a;
}

function fieldstourl(fields) {
  var fielddata = {};

  for (var i = 0; i < fields.length; i++) {
    var element = $('#' + fields[i]);

    if (element.length) {
      if (isInt(element.val())) {
        if (element.val() > 0) {
          fielddata[fields[i]] = element.val();
        }
      } else {
        if (element.val()) {
          fielddata[fields[i]] = element.val();
        }
      }
    }
  }

  return $.param(fielddata);
}

function validateform() {
  var type = $('#searchtype').val();
  var validations;

  if (type === 'flighthotel') {
    validations = {
      'depart': 'departure airport',
      'hotelcode': 'destination',
      'dateinput': 'travelling dates'
    };
  } else if (type === 'hotel') {
    validations = {
      'hotelcode': 'destination',
      'dateinput': 'travelling dates'
    };
  }

  for (var key in validations) {
    if ($('#' + key).val() === '') {
      alert('Please select the ' + validations[key] + ' of your holiday.')
      return false;
    }
  }

  return true;
}

function initsearchform(selector, childredirect) {
  $(selector).on('submit', function (event) {
    event.preventDefault();
    var adults = 0;
    var children = 0;
    var infants = 0;
    var searchform = $(selector);

    var rooms = $('#roomcount').val();

    for (var i = 1; i <= rooms; i++) {
      adults += parseInt($('#adults-' + i).val());
      children += parseInt($('#children-' + i).val());
      infants += parseInt($('#infants-' + i).val());
    }

    if (childredirect) {
      if (validateform() === true) {
        var inputs = ['depart', 'hotelcode', 'dateinput', 'roomcount', 'flightclass', 'promocode', 'startdate', 'depdate', 'enddate', 'nights',
          'startdate-day', 'startdate-month', 'startdate-year', 'enddate-day', 'enddate-month', 'enddate-year',
          'depdate-day', 'depdate-month', 'depdate-year',
          'adults-1', 'adults-2', 'adults-3', 'adults-4',
          'children-1', 'children-2', 'children-3', 'children-4',
          'infants-1', 'infants-2', 'infants-3', 'infants-4'];

        if (adults >= infants && children) {
          var hotelonly = searchform.find('.button__searchtype--hotelonly').hasClass('active');
          var fields = fieldstourl(inputs);

          if (hotelonly === true) {
            fields = 'hotelonly=true&' + fields;
          }

          window.location = 'https://booking.beaches.co.uk/search?' + fields;
        } else if (adults < infants) {
          alert('To ensure we comply with airline safety regulations we have to limit bookings to one infant per adult travelling.');
          return false;
          // var hotelonly = searchform.find('.button__searchtype--hotelonly').hasClass('active');
          // var fields = fieldstourl(inputs);

          // if (hotelonly === true) {
          //   fields = 'hotelonly=true&' + fields;
          // }

          // window.location = 'https://booking.beaches.co.uk/search?' + fields;
        } else {
          $('#infants').val(infants);
          $(selector).get(0).submit();
        }
      }
    } else if (validateform() === true) {
      $('#adults').val(adults);
      $('#children').val(children);
      $('#infants').val(infants);

      $(selector).get(0).submit();
    }
  });
}