/**
*   Cookie Class
*   Created by: David Nuñez
*   May 17, 2018
*/

class Cookie {

  /**

   *  Method: hasCookie

      @cookieName type String
      Returns a Boolean.
  */

  static has(cookieName) {
    const cookies = document.cookie.split(';');

    const foundCookie = cookies.find(item => item.includes(cookieName + '='));

    return !! (typeof foundCookie !== 'undefined' && foundCookie.length);

  }

  /**
   *  Method: hasCookie

      @cookieName type String
      Returns a Boolean.
  */
  static get(cookieName) {
    const cookies = document.cookie.split(';');

    const foundCookie = cookies.find(item => item.includes(cookieName + '=')) || '';

    const value = foundCookie.trim().split('=')[1];

    return value || '';
  }
}


export default Cookie;
