// Load Modules =================================
import jsend from 'jsend';

/* eslint-disable */

// Local scope ==================================
const internals = {
  headers: {
    'Accept': 'application/json, application/xml, text/plain, text/html, */*',
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
  },
  credentials: 'include',
  // isIE9: navigator.appName === 'Microsoft Internet Explorer' && navigator.appName.indexOf('MSIE 9'),
  normalizeData: (components) => {

    const result = {};

    Object.keys(components).forEach((key) => {
      result[key] = components[key].state.value;
    });

    return result;
  },
  getBody: (data) => {

    let body = '';

    Object.keys(data).forEach((key) => {

      body += `&${key}=${encodeURIComponent(data[key])}`;
    });
    body = body.substr(1); // Removing first character since it is '&'

    return body;
  }
};

// Exposing fetch wrapper =======================
export default class goFetch {

  constructor(opts) {

    this.options = Object.assign({}, internals, opts);
  }

  post(urlPath, components = null, data = {}) {

    const method = 'POST';
    const { headers, credentials } = internals;
    const _data = (components) ? internals.normalizeData(components) : data;
    const body = internals.getBody(_data);

    const fetchPromise = fetch(urlPath, { headers, method, body, credentials });

    return fetchPromise.then((response) => {

      if (response.ok) {

        return response.json();
      }

      return jsend.error('Network response was not ok');
    })
      .then(response => {

        return response;
      })
      .catch(err => {
        // eslint-disable-next-line
        logError(document.location.toString().replace(/#.*$/, ""), err); // Logs errors to Splunk
      });
  }

  submitForm(urlPath, data = {}, isAppJson = false) {
    const method = 'POST';
    const { headers, credentials } = internals;
    const dataKeys = Object.keys(data);
    const body = isAppJson ? JSON.stringify(data) : new FormData();
    const headersCopy = Object.assign({}, headers);

    if (isAppJson) {
      headersCopy['Content-Type'] = 'application/json';
    } else {
      dataKeys.map(key => {
        body.append(key, data[key]);
      });

      // setting content type
      delete headersCopy['Content-Type'];
    }

    const fetchPromise = fetch(urlPath, { headersCopy, method, body, credentials });

    return fetchPromise.then((response) => {

      if (response.ok) {

        return response.json();
      }

      return jsend.error('Network response was not ok');
    })
      .then((response) => {

        return response;
      })
      .catch((err) => {
        // eslint-disable-next-line
        logError(document.location.toString().replace(/#.*$/, ''), err); // Log errors to Splunk
      });
  }

  get(urlPath) {
    const method = 'GET';
    const { headers, credentials } = internals;

    return fetch(urlPath, { headers, credentials, method })
      .then(response => {
        console.log('response action: ', response);
        if (response.ok) {
          return response.json();
        }

        return jsend.error('Network response was not ok');
      });
  }

}
