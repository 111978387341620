import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import GoFetch from "~/js/modules/fetch-wrapper";

const fetch = new GoFetch();
const logInBtn = document.querySelector('.js-login-btn');
const logInBtn2 = document.querySelector('.js-login-btn-2');
const logInSm = document.querySelector('.user-login');
const mainMenu = document.querySelector(".hm-menu-btn");
const headerBottom = document.querySelector(".header-bottom");
const profileOptions = document.querySelector('.profile-options');
const profileOptions2 = document.querySelector('.profile-options-2');
const atributteClasses = ["icon-overview", "icon-profile", "icon-trips", "icon-rewards", "icon-contact", "icon-offers"]

class ProfileOptions extends Component {

  constructor(){

    super();

    const { env } = window.beaches_app;

    this.state = {
      links: [],
      newlinks: [],
      pointsValue: "",
      pointsBalance: "",
      env
    };
  }

  componentDidMount() {
    this.getData();
    this.getPoints();
  }
  
  signOutUser = () => {

    const urlSignOutPrd = 'https://accountscms.beaches.co.uk/api/auth/sign-out/';
    const urlSignOutDev = 'https://accountscms.beaches.co.uk/api/auth/sign-out/';
     
    const urlSignOut = this.state.env == 'prd' ? urlSignOutPrd : urlSignOutDev;
     
    fetch.get(urlSignOut)
    .then(response => {
      const { status } = response;

      if(status==200){
        
        logInBtn.classList.remove("pd-btn");
        logInBtn2.classList.remove("pd-btn");
        logInBtn.classList.remove('js-rewards-btn');
        logInBtn2.classList.remove('js-rewards-btn');
        profileOptions.classList.add('hide-options');
        profileOptions2.classList.add('hide-options');

        logInBtn.innerHTML= `<i class="icon-sign-in"></i> SSG Sign up/Login`;
        logInBtn2.innerHTML = `<i class="icon-sign-in"></i> SSG Sign up/Login`;
        logInSm.innerHTML =`<i class="ic-sign icon-sign-in"></i> SSG Sign up/Login`;
      }
     
      
    
    })
    .catch(error => console.log(error));

      location.reload();
      mainMenu.classList.add("hm-menu-btn--active");
      headerBottom.classList.add("header-bottom--open");


};

getData = () => {

  console.log("getLayout");

  const urlLayoutPrd = 'https://accountscms.beaches.co.uk/api/layout?locale=en-gb';
  const urlLayoutDev = 'https://accountscms.beaches.co.uk/api/layout?locale=en-gb';
   
  const urlLayout = this.state.env == 'prd' ? urlLayoutPrd : urlLayoutDev;
     
    fetch.get(urlLayout)
    .then(response => {
      const { data = {} } = response;

      let links = Array.from(data.modules[1].links);

      let newlinks = [];

      for (let i = 0; i < links.length ; i++) {
          let newItem = {
              ...links[i],
              classes: atributteClasses[i]
          };
          newlinks.push(newItem);
      }

      this.setState({ newlinks });
    })
    .catch(error => console.log(error));
  
};

getPoints = () => {

  const urlPointsPrd = 'https://accountscms.beaches.co.uk/api/loyalty/points/';
  const urlPointsDev = 'https://accountscms.beaches.co.uk/api/loyalty/points/';
   
  const urlPoints = this.state.env == 'prd' ? urlPointsPrd : urlPointsDev;
     
  fetch.get(urlPoints)
  .then(response => {
    const { status, content = {} } = response;

    if(status == 200){

      this.setState({ pointsValue: "Points Value: $" + content.points_available_usd.toLocaleString() + " (USD)"});
      this.setState({ pointsBalance: "Available Points Balance: " + content.points_available.toLocaleString()});

    }

  })
  .catch(error => console.log(error));

};

render() {
//const accountUrl = this.state.env == "prd" ? 'https://beaches.co.uk/my-account/' : 'https://tstaccounts.sandals.co.uk/my-account/';
return (
  <Fragment>
     <div className="user-points">
       <p className="points-balance">{this.state.pointsBalance}</p>
       <p className="points-value">{this.state.pointsValue}</p>
     </div>
     <div className="user-options">
      {this.state.newlinks.map(data => <div className="option" key={data.label}><a href={'/my-account/'+ data.href}> <i className={data.classes}></i> {data.label}</a></div>)}
       <div className="option"><a onClick= {this.signOutUser}> <i className="icon-log-out"></i> Sign Out</a></div>
     </div>
  </Fragment>
);
}


}

export default ProfileOptions;
