const imgLoad = () => {

  if (window.addEventListener && window.requestAnimationFrame && document.getElementsByClassName) {
    window.addEventListener('load', () => {

      const pItem = document.getElementsByClassName('progressive js-replace');
      let timer;

      const loadFullImage = (item) => {

        if (!item || !item.getAttribute('data-large')) {
          return;
        }

        const img = new Image();

        if (item.dataset) {
          img.srcset = item.dataset.srcset || '';
          img.sizes = item.dataset.sizes || '';
        }

        img.src = item.getAttribute('data-large');
        img.className = 'reveal';

        const addImg = () => {
          // item.addEventListener('click', function(e) { e.preventDefault(); }, false);
          item.appendChild(img).addEventListener('animationend', (e) => {
            const pImg = item.querySelector && item.querySelector('img.preview');

            if (pImg) {
              e.target.alt = pImg.alt || '';
              item.removeChild(pImg);
              e.target.classList.remove('reveal');
            }

          });

        };

        if (img.complete) {
          addImg();
        } else {
          img.onload = addImg;
        }

      };

      const inView = () => {

        const wT = window.pageYOffset;
        const wB = wT + window.innerHeight;
        let cRect = 0;
        let pT = 0;
        let pB = 0;
        let p = 0;

        while (p < pItem.length) {

          cRect = pItem[p].getBoundingClientRect();
          pT = wT + cRect.top - 400;
          pB = pT + cRect.height;

          if (wT < pB && wB > pT) {
            loadFullImage(pItem[p]);
            pItem[p].classList.remove('js-replace');
          } else {
            p++;
          }

        }

      };

      const scroller = () => {

        timer = timer || setTimeout(() => {
          timer = null;
          requestAnimationFrame(inView);
        }, 300);

      };

      window.addEventListener('scroll', scroller, false);
      window.addEventListener('resize', scroller, false);
      inView();

    }, false);

  }

  if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
    $('.progressive').each(function ieFallback() {
      const $container = $(this);
      const lgImgUrl = $container.data('large');

      if (lgImgUrl) {
        $container
          .css('backgroundImage', 'url(' + lgImgUrl + ')')
          .addClass('objectFit-backgroundImg')
          .find('img').remove();
      }
    });
  }

};

export default imgLoad;
